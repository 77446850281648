import React from 'react';
import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import { graphql } from 'gatsby';

import { ColumnSection, Column } from '../../../components/column-section';

import RelatedNewsSection from '../../../components/related-news-section';
import ReactMarkdown from 'react-markdown';
import { Languages } from '../../../tools/languages';
import ContactCTA from '../../../components/contact-cta';

const DutchVacancyDetailPage = ({ location, data, language = Languages.NL }) => {
  const vacancy = data.strapiVacancies;
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title={vacancy.title} lang={language} />
      <Navigation transparent />
      <main>
        <SimpleHero image={vacancy.image.childImageSharp.original.src} alt={vacancy.title}>
          <h1>{vacancy.title}</h1>
        </SimpleHero>
        <ColumnSection>
          <RelatedNewsSection language={language} />
          <Column>
            <ReactMarkdown>{vacancy.description}</ReactMarkdown>
          </Column>
        </ColumnSection>
        <ContactCTA language={language} to={`/contact?vacancy=${vacancy.title}`} />
      </main>
    </>
  );
};

export const query = graphql`
  query DutchVacancyDetail($slug: String!) {
    strapiVacancies(slug: { eq: $slug }) {
      slug
      title: title_nl
      description: description_nl
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default DutchVacancyDetailPage;
